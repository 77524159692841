import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { Container } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";

const SimplePage = ({ data }) => {
  if (!data) return null;

  const { page } = data;
  const { title, content } = page.data;

  return (
    <>
      <Seo page={page} />
      <Layout>
        <Container>
          <h1 className="mb-5 py-5 text-center">{title}</h1>
          <PrismicRichText field={content.richText} />
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query simplePageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicSimplePage(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicSimplePageFragment
    }
  }
`;

export default SimplePage;
